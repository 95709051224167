import React, { useContext } from "react";
import { GlobalDataContext } from "../../../../context/context";
import "photoswipe/dist/photoswipe.css";
// import { Gallery, Item } from "react-photoswipe-gallery";
import Gallery from "./PojectContent"

const GalleryPage = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    
    <div className="container pd-top-150 pd-bottom-100">
      {/* <h2 className="text-center">Our Recent Projects</h2> */}
      {/* <div className="gallery-content"> */}
      {
            rpdata?.landings?.length > 0 ?
              rpdata?.landings?.map((item, index) => {
                return (
                  <div className="pt-20 pb-32 contgallery">
                    <div className="text-center py-5" key={index}>
                      <h2 className="text-center">{rpdata?.labels?.general?.titleGallery}</h2>
                      <h2 className="capitalize">{item.name}</h2>
                    </div>
                    <Gallery galleryImages={item.gallery} />
                  </div>
                )
              })
              :
              <div className="pb-32">
                <div className="text-center py-5">
                  <h2 className="text-center pt-[100px]">{rpdata?.labels?.general?.titleGallery}</h2>
                </div>
                <Gallery galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock} />
              </div>
          }


      {/* <Gallery>
        {rpdata?.gallery?.map((item, index) => (
          <Item original={item} thumbnail={item} width="1024" height="768" padding="10px">
            {({ ref, open }) => <img ref={ref} onClick={open} src={item} alt="not found"/>}
          </Item>
        ))}
      </Gallery> */}
    </div>
    // </div>
    
  );
};

export default GalleryPage;